<template>
  <mobile-screen :header="true" screen-class="resources-screen gray-bg">
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="resource-header icon-hea1">
        <template v-slot:left>
          <router-link
            :to="{
              name: backLinkName
            }"
          >
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          {{ displayLabelName("containers", pageName, pageName) }}
        </div>
        <template v-slot:right>
          <button @click="initSubmit">
            <icon icon="#cx-hea1-save" />
          </button>
        </template>
      </top-header-menu-wrapper>
    </template>
    <VeeForm
      ref="addResourceTypeForm"
      @submit="submitForm"
      v-slot="{ errors }"
      class="form inline-input edit-form"
      novalidate
    >
      <ul class="clebex-item-section">
        <li
          class="clebex-item-section-item"
          :class="{ 'has-error': errors.name }"
        >
          <span class="error-message" v-if="errors.name">
            {{ errors.name }}
          </span>
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label">
                {{ displayLabelName("containers", pageName, "name") }}
              </label>
              <Field
                name="name"
                as="input"
                type="text"
                rules="required"
                :placeholder="displayLabelName('containers', pageName, 'name')"
              />
            </div>
          </div>
        </li>
        <li
          v-if="pageName === 'add-user-container'"
          class="clebex-item-section-item"
        >
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label">
                {{
                  displayLabelName(
                    "containers",
                    pageName,
                    "azure-user-group-id"
                  )
                }}
              </label>
              <Field
                name="azure_group_id"
                as="input"
                type="text"
                :placeholder="
                  displayLabelName(
                    'containers',
                    pageName,
                    'azure-user-group-id'
                  )
                "
              />
            </div>
          </div>
        </li>
        <li class="clebex-item-section-item">
          <div class="clebex-item-content-wrapper">
            <dl class="clebex-item-dl justify-start">
              <dt class="clebex-item-dt">
                <div class="checkbox slide">
                  <input
                    id="containersContainAll"
                    type="checkbox"
                    v-model="containAll"
                  />
                  <label for="containersContainAll"></label>
                </div>
              </dt>
              <dd class="clebex-item-dd justify-start">
                {{ displayLabelName("containers", pageName, "contain-all") }}
              </dd>
            </dl>
          </div>
        </li>
      </ul>
      <button type="submit" style="display: none;" ref="submitFormBtn">
        Submit form
      </button>
    </VeeForm>
  </mobile-screen>
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import httpServiceAuth from "@/services/http-service";
import { apiEndpoints } from "@/services/constants";
import { errorHandler } from "@/services/error-handler";
import containersMixin from "@/services/mixins/containers/containers-mixin";
export default {
  name: "AddContainer",
  data() {
    return {
      containAll: false
    };
  },
  computed: {
    pageName() {
      const { containerType } = this;
      if (containerType === "USER") {
        return "add-user-container";
      } else if (containerType === "RESOURCE") {
        return "add-resource-container";
      } else if (containerType === "LEVEL") {
        return "add-level-container";
      } else if (containerType === "USER_MANAGER") {
        return "add-user-manage-container";
      } else if (containerType === "RECEPTION_DESK") {
        return "add-reception-desk-container";
      }
      return "";
    }
  },
  methods: {
    submitForm(values) {
      if (values) {
        this.submitCompanyData(values);
      }
    },
    submitCompanyData(values) {
      values.access_type_id = this.containerTypeId;
      values.contain_all = Number(this.containAll);
      this.$store.commit("loader/setScreenLoading", true, { root: true });
      httpServiceAuth
        .post(apiEndpoints.company.containers, values)
        .then(() => {
          this.fetchContainers();
          this.$refs.addResourceTypeForm.setValues({
            name: ""
          });
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response, this.findElement());
          }
        })
        .finally(() => {
          this.$store.commit("loader/setScreenLoading", false, { root: true });
        });
    },
    initSubmit() {
      const { submitFormBtn } = this.$refs;
      submitFormBtn.click();
    }
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  },
  mixins: [containersMixin]
};
</script>
